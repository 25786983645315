:root {
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
}

body {
  --bg-color: #fff;
  --fg-color: #9BABC6;
  --bg-light-color: rgba(255,255,255,0.9);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-color);
}
/*  Dark mode */
body[data-theme='dark'] {
  --bg-color: #191919;
  --bg-light-color: #303030;
  --fg-color: #e8e8e8;
  background: var(--bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
